<template>
<div id='question_app'>
<div class="q_body">
  <div class="q_item" >
    <div class="tit">
       <span>请填写车辆的整备质量kg（必填） </span>
    </div>
    <div>
      <div class="options" >
        <div>
          <van-field v-model="model.autoWeight" type="number" placeholder="请输入整备质量" />
        </div>
      </div>
    </div>
  </div>
</div>
  <QuestionTemplate :questionList="questionList" @subCustomerPrice='subCustomerPrice'></QuestionTemplate>
  <div id="bottom_div"></div>
</div>
</template>

<script>
import { RadioGroup, Radio,Field } from 'vant';
import QuestionTemplate from '../../components/questionTemplate.vue'
import {GetQuestionList} from '../../api/common'
import {SaveCollection,providerSave,saveFromQRcode} from '../../api/provider'
export default {
    components:{
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Field.name]: Field,
      QuestionTemplate
    },
    data(){
      return {
        questionList:[],
        weight:'',
        model:'',
        isCustomer:false,
        brandName:''
      };
    },
    methods:{
      subCustomerPrice(data) {
        if(this.model.autoWeight<=300){         
          this.$toast.fail("整备质量不能小于300")
          return
        }
        this.model.cQuestionJson = JSON.stringify(data);
        this.$toast.loading({duration: 0,forbidClick: true,message: '估价中',});
        // this.model.autoWeight = Math.floor(this.model.autoWeight/100)*100
        // TODO 这里区分角色，最好不要在这里用ifelse判断
        if (this.isCustomer) {
          SaveCollection(this.model).then((res) => {
            this.$toast.clear();
            if (res.status) {
              this.$router.replace({
                path: "/orderPricing",
                query: {
                  car_name: this.brandName,
                  total_price: res.data.price,
                  collectId: res.data.cid,
                  isCustomer: this.isCustomer,
                },
              });
            } else {
              this.$toast.fail(res.message);
            }
          });
        } else {
          this.saveProvider();
        }
      },
      saveProvider() {
        var pid = this.$route.query.providerId;
        if (!pid) {
          this.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: "保存中",
          });
          providerSave(this.model)
            .then((res) => {
              this.$toast.clear();
              if (res.status) {
                debugger
                var price = res.data.item1 && Math.floor(res.data.item1);
                this.$router.replace({
                  path: "/orderPricing",
                  query: {
                    car_name: this.brandName,
                    total_price: price,
                    collectId: res.data.item2,
                  },
                });
              } else {
                this.$toast(res.message);
              }
            })
            .catch((res) => {
              console.log(res)
              this.$toast.clear();
              this.$toast.fail("请重试！");
            });
        } else {
          this.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: "保存中",
          });
         saveFromQRcode(this.model)
            .then((res) => {
              this.$toast.clear();
              if (res.status) {
                var price = res.data.price && Math.floor(res.data.price);
                this.$router.replace({
                  path: "/orderPricing",
                  query: {
                    car_name: this.brandName,
                    total_price: price,
                    collectId: res.data.collectId,
                    providerId: this.model.providerId,
                  },
                });
              } else {
                this.$toast(res.message);
              }
            })
            .catch(() => {
              this.$toast.clear();
              this.$toast.fail("请重试！");
            });
        }
      }
    },
    created(){
      this.isCustomer = this.$route.query.isCustomer ==='true';
      this.model = JSON.parse(window.sessionStorage.getItem("uploadOrder_Model")||'');
      this.brandName = this.model.model;
      console.log('model',this.model)
      var prefix = this.model.licensePlate&&this.model.licensePlate.substring(0,2);
      GetQuestionList({province:this.model.province,city:this.model.city,licensePrefix:prefix,agentId:0})
      .then(res=>{
        if(res.length>0){         
          this.questionList = res;
          console.log(this.questionList);
        }
      })
      // window.sessionStorage.setItem("uploadOrder_Model",'');
    }
}
</script>

<style scoped>
.q_body{
  padding-top: 10px;
}
.q_item{
  position: relative;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.options .option_item{
  width: 94%;
  margin: 0px auto;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  margin-top: 4px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
}
.options .checkbox_item{
  width: 47%;
  display: inline-block;
  margin-left: 2%;
}
.q_body{
  width: 94%;
  margin: 0px auto;
}
.tit{
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}
.options .active_option{
  border:1px solid #4600af;
}
.edit{
  position: absolute;
  right: 20px;
  top: 10px;
}
.btn_panel{
  width: 94%;
  margin: 0px auto;
  height: 50px;
  line-height: 50px;
  background-color: #4600af;
  color: white;
  position: absolute;
  bottom: 0px;
  text-align: center;
  border-radius: 10px;
  left: 3%;
  right: 3%;
}
.next_btn{
  width: 94%;
  margin:0px auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  background-color: #4600af;
  color: white;
  margin-top: 10px;
}
#bottom_div{
  height: 30vh;
  width: 100%;
}
</style>>
